@import url("https://use.typekit.net/iaz3vqo.css");

/* home img + navbar */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
  }
  .home-nav-logo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 105px;
    margin-right: 100px;
    padding-top: 48px;
  }
  .home-nav-logo-contacto{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 105px;
    margin-right: 100px;
    padding-top: 48px;
  }

  .li{
    color: #FFF;
    text-decoration: none;
  }
  .ul-nav{
    display: flex;
    flex-direction: row;
    list-style: none;
    cursor: pointer;
    background-color: transparent;
    margin-top: 30px;
    gap: 30px;
    font-size: 12px;
    font-family: "Nunito";
  }
  .ul-nav-redes{
    display: flex;
    flex-direction: row;
    list-style: none;
    cursor: pointer;
    background-color: transparent;
    margin-top: 30px;
    gap: 10px;
    font-size: 12px;
  }
  .logo-toggle{
    display: none;
  }
  .logo-toggle2{
    display: none;
  }
  .logoppal{
    margin-top: 20px;
  }
  .imagen-home{
    background-image: url(./Assets/imagen-home.png);
    background-size: cover;
    background-repeat: no-repeat;
    height:600px;
  }
  .navbar-links-container{
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .redes-nav{
    width: 17px;
    height: 17px;
  }
  /* .react-multi-carousel-list{
    margin-right: -275px;
  } */
  /* home + cards */
  .container-home-cards{
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-bottom: -34px;
    margin-top: 100px;
    flex-wrap: wrap;
  }
  .titulo-cardscontainer{
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-bottom: -69px;
  }
  .titulo-cards{
    color: #373737;
    text-align: center;
    font-size: 26px;
    font-weight: 200;
    font-family: "roc-grotesk", sans-serif;
  }
  .titulo-cards-mas{
    color:#E6BA2F;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    font-family: "roc-grotesk", sans-serif;
  }
  .card-servicios{
    display: flex;
    flex-direction: column;
    width: 275px;
    height: 399px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 7px 16px 20px 0px rgba(0, 0, 0, 0.25);
  }
  .titulo-cardA, .titulo-cardB, .titulo-cardC, .titulo-cardD{
    color: #6E6E6D;
    text-align: center;
    font-size: 26px;
    font-weight: 200;
    line-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "roc-grotesk", sans-serif;
  }
  .contenedor-circulo-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
  }
  .circulo-logo{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: #F7F7F7;
  }
  .logo-card{
    width: 60px;
    height: 60px;
    margin-top: 19px;
    margin-left: 21px;
  }
  .text-card-servicios{
    color:#5F6368;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    font-size: 15px;
    font-family: "inter";
  }
  .hero-titulo-home{
    width: 600px;
    color: #f8f8f8;
    font-size: 40px;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 200;
    
  }
  .container-hero-home{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 100px;
    flex-direction: column;
  }
  .hero-subt-home{
    font-size: 30px;
    color: #f8f8f8;
    font-weight: 200;
    margin-top: 10px;
  }
    
  
      
 

  /* carrusel+contenedor */

  .carrusel-gris-img{
    display: flex;
    flex-direction: row;
    background-color:#F7F7F7;
    padding-top: 100px;
    height: 625px;
  }
  .carrusel-am{
    width: 70%;
    height: 450px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
  .cartelito-contenedor{
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .cards-carruselam3{
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    gap: 10px;
    margin-left: 50px;
  }
  .boton-siguiente{
    background-color: transparent;
    border: none;
  }
  .img-nosotros{
    height: 248px;
    width: 350px;
    border-radius: 5px;
  }
  .img-nosotros-serv{
    height: 248px;
    width: 350px;
    border-radius: 5px;
  }
  .circulo-carrusel{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #E6BA2F;
  }
  .circulos-carrusel-home{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
    margin-left: -162px;
  }
  .circulo-img{
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .parrafo-home-carruselimg{
    color: #373737;
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px;
    font-family: "inter";
  }
  .cuadrito-contacto{
    width: 400px;
  }
  .parrafo-home-carruselimg-contacto{
    color: #373737;
    font-size: 17px;
    font-weight: 200;
    line-height: 15px;
    margin-top: 10px;
    width: 400px;
    font-family: "inter";
  }
  .text-container-cartelito{
    margin-top: -80px;
    margin-left: 50px;
  }
  .titulo-circulo{
    color: #E6BA2F;
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 500;
  }

  .titulo-pequeño{
    color: #373737;
    font-size: 12px;
    font-family: "roc-grotesk", sans-serif;
  }

  /* proyectos home */

  .container-txt-proyectos-home{
    margin-left: 50px;
    margin-top: 100px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .cuadros-proyectos-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 50px;
    margin-top: 50px;
  }
  .foto-ppal-cuadros-proyectos{
    width: 40%;
    background-image: url(./Assets/lasliebresedit.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    border-radius: 5px;
    background-position-y: -29px;
    
  }
  .foto-chiquita-cuadros-proyectos{
    width: 40%;
    height: 300px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    
  }
  .dosfotos{
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .foto-chiquita{
    width: 50%;
    height: 195px;
    background-image: url(./Assets/losnogalesedit.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
  }
  .foto-chiquita2{
    width: 50%;
    height: 195px;
    background-image: url(./Assets/ciudadoestedit.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
  }
  .foto-chiquita3{
    width: 50%;
    height: 195px;
    background-image: url(./Assets/casonasedit.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
  }
  .foto-chiquita4{
    width: 50%;
    height: 195px;
    background-image: url(./Assets/latrinidadedit.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
  }

  .boton-home {
    font-size:10px;
    width:165px;
    height:38px;
    border-width:1px;
    color:#fff;
    border-color:#E6BA2F;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    background:#E6BA2F;
    margin-top: 30px;
    margin-left: 50px;
  }
  
  .boton-home:hover {
    background: rgba(240, 185, 92, 1)
  }

  .titulo-cuadros-proyectos {
    font-size: 28px;
    color: #f8f8f8;
    margin-left: 30px;
    margin-top: 333px;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 200;
}
  .parrafo-home-carrusel{
    font-size: 10px;
    color: #373737;
    margin-left: 30px;
  }

  .titulo-cuadros-proyectosB {
    font-size: 19px;
    color: #f8f8f8;
    margin-left: 30px;
    margin-top: 149px;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 200;
}
  .parrafo-home-carruselimgB{
    font-size: 8px;
    color: #373737;
    margin-left: 10px;
  }
  .recuadros-proyectos{
    margin-bottom: 100px;
  }
  
  /* carrusel de logos */
  
  .react-multiple-carousel__arrow{
    display: none;
  }

  .carrusel-marcas{
    margin-bottom: 100px;
    margin-left: 50px;
  }

  .logos-carrusel{
    margin-left: 50px;
  }
  
  /* comentarios */

  .container-comentarios{
    background-color: #6D6D6D;
    padding-top: 30px;
    margin-top: 100px;
  }
  .titulos-seccion-comentarios{
    margin-left: 50px;
    margin-top: 100px;
    color: #F7F7F7;
    width: 400px;
  }

  .titulo-pequeño-comen{
    color: #F7F7F7;
    font-size: 15px;
    font-family: "roc-grotesk", sans-serif;
  
  }
  .titulo-pequeño-comen-servicios{
    color: #373737;
    font-size: 15px;
  
  }
  .cards-comentarios{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 30px;
    margin-left: 50px;
    margin-right: 50px;
    padding-bottom: 110px;
  }
  .coment{
    width: 30%;
  }
  .comilla{
    font-size: 90px;
    color: #E6BA2F;
  }
  .titulo-pequeño-comen1{
    color: #F7F7F7;
    font-size: 13px;
    width: 265px;
    font-family: "inter";
  }
  .lineademas{
    font-size: 20px;
    color: #E6BA2F;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .titulo-nombre{
    color: #F7F7F7;
  }

  /* contacto */

  .contacto-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .todo-elcontacto{
   display: flex;
   flex-direction: row-reverse;
   gap: 50px;
  }

  .contact-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .input-field {
    position: relative;
  }
  
  .input-field input, .input-field textarea {
    padding: 10px;
    border: 1px solid #ccc; 
    transition: border-color 0.3s;
    outline: none;
  }
  
  .input-field label {
    position: absolute;
    top: 3px;
    left: 10px;
    transition: 0.3s;
  }
  
  .input-field input:focus + label,
  .input-field textarea:focus + label {
    top: -20px;
    font-size: 12px;
    color: #E6BA2F;
  }
  
  .input-field input:focus, .input-field textarea:focus {
    border-color:#E6BA2F;
  
  }
  
  .input-field label.hide {
    display: none;
  }
  
  .input-contacto{
    background: #FFF;
    width: 400px;
  height: 15px;
  color: #878179;
  
  }
  
  .input-contacto2{
    background: #fff;
    width: 400px;
  height: 120px;
  color: #878179; 
  }
  
  .nombre{
    color: #878179;
    font-size: 14px;
  }
  
  .form-contacto{
    display: flex;
    flex-direction: column;
    gap: 23px;
  }
  
  .p-enviado{
    margin: 0;
    font-size: 14px;
    color: #FFF;
    font-weight: 400;
    padding-bottom: 40px;
  }
  
  .input-field input.has-value + label,
  .input-field textarea.has-value + label {
    top: -20px;
    font-size: 12px;
    color: #FFF;
  }
  .boton-contacto {
    font-size:10px;
    width:165px;
    height:38px;
    border-width:1px;
    color:#fff;
    border-color:#E6BA2F;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    background:#E6BA2F;
    margin-top: 30px;

  }
  .boton-contacto:hover {
    background: rgba(240, 185, 92, 1)
  }
  .contacto-redes-iconos{
    margin-top: 50px;
  }
  .parrafo-vector-contacto{
    color: #373737;
    font-size: 13px;
  }
  .vector-contacto{
    margin-right: 10px;
  }

  .titulo-contacto{
    width: 265px;
  }
  .titulo-contacto2{
    width: 265px;
  }

  /* footer */
  .todo-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  /* servicios inicial */
 
  .home-nav-logo-nosotros{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 150px;
    margin-right: 100px;
  }
  .img-nosotros2{
    height: 348px;
    width: 600px;
    border-radius: 10px;
  }
  .imagen-servicios{
    background-image: url(./Assets/servicios-hero-com.png);
    background-size: cover;
    background-repeat: no-repeat;
    height:800px;
  }
  .imagen-servicios-proyectos{
    background-image: url(./Assets/proyecto-hero.png);
    background-size: cover;
    background-repeat: no-repeat;
    height:800px;
  }
  .container-txt-carruselppal {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 236px;
    margin-top: 133px;
    margin-left: 112px;
}

  .cards-carruselam2{
    margin-top: 36px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .titulo-pequeño-servicios {
    color: #FAFAFA;
    font-size: 15px;
    font-family: "roc-grotesk", sans-serif;
}
.parrafo-pequeño-servicios {
  color: #FAFAFA;
  font-size: 22px;
  font-weight: 400;
  line-height: 31px;
  margin-top: 10px;
  margin-bottom: 60px;
  width: 421px;
  font-family: "inter";
}
  .titulo-grande-servicios {
    color: #FAFAFA;
    font-size: 43px;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 200;
}
  .indicadores-circulos{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circulo {
    height: 10px;
    width: 10px;
    margin: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .circulo.activo {
    background-color:#E6BA2F;
    width: 60px;
    border-radius: 10px;
  }

  .text-red-ppal{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .flechas-carrusel{
    padding-top: 66px;
    display: flex;
    gap: 85px;
  }

  /* servicios-carruselamarillo */

  
  .carrusel-amarillo{
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 1;
    height: 600px;
  }
  .cartelito-contenedor-carruselam{
    width: 20%;
    background-color: #E6BA2F;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .text-container-cartelito-carruselam{
    margin-top: -12px;
  }

  .obras-letrachica {
    font-size: 17px;
    font-weight: 100;
    color: #fff;
    margin: 0%;
    margin-right: 50px;
    font-family: "roc-grotesk", sans-serif;
}

.titulo-obras {
  color: #FFF;
  margin: 0px;
  font-size: 52px;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 200;
}

.subtitulo-obras{
  font-size: 25px;
  color:#FFF;
  margin: 0%;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 200;
}

  .boton-cartelito{
    font-size:10px;
    width:165px;
    height:38px;
    border-width:1px;
    color:#fff;
    border-color:#6D6D6D;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    background:#6D6D6D;
    margin-top: 30px;
  }

  .carrusel-am2{
    width: 80%;
    height: 450px;
    background-color:#F7F7F7;
    display: flex;
    flex-direction: column;
  }
  .linea-coment3-trabajos {
    width: 379px;
    border: 0;
    border-top: 2px solid #E6BA2F;
    margin-bottom: 30px;
    margin-top: 10px;
}
  .nombre-trabajos{
    color: #5F6368;
    margin-bottom: -10px;
    margin-top: -20px;
    font-size: 20px;
  }


  .txt-card-trabajos {
      color: #5F6368;
      margin-bottom: -10px;
      margin-top: 10px;
      font-size: 15px;
      width: 393px;
  }
  

  /* servicios video carrusel */

  .card-v2 {
    background-color: #5F6368;
    height: 299px;
    padding: 40px 40px 40px 40px;
    width: 400px;
    border-radius: 10px;
    margin-top: 28px;
}


  .contenedor-carruselvideo-botones{
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .video-red{
    width: 600px;
  }

  .card-video {
    margin-top: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 98px;}

  .titulo-icons-red{
    color:#FFF;
    margin: 0px;
    font-size: 30px;
  }

  .icons-letrachica3{
    font-size: 12px;
    font-weight: 100;
    color: #fff;
    margin: 0%;
  }

  .icons-letrachica4{
    font-size: 20px;
    font-weight: 100;
    color:#E6BA2F;
    margin: 0%;
    margin-top: 40px;
  }

  /* comentarios servicios */

  .container-comentarios2 {
    padding-top: 30px;
    margin-top: 100px;
}
.titulos-seccion-comentarios2 {
  margin-left: 113px;
  margin-top: 100px;
  color: #373737;
}
.titulo-pequeño-comen2 {
  color: #373737;
  font-size: 13px;
  width: 352px;
}


.titulo-nombre2 {
  color: #373737;
}

/* proyectos */
/* carrusel */
.cards-obras-nuestro{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.container-cards-nosotros{
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  flex-wrap: nowrap;
  margin-top: 70px;
  flex-wrap: wrap;
}
.card-nosotros {
  height: 434px;
  width: 418px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 7px 16px 20px 0px rgba(0, 0, 0, 0.25);
}
  .img-nosotros-obras {
    height: 269px;
    width: 419px;
    border-radius: 10px;
  }

  .linea-coment3-trabajos{

    border: 0;
    border-top: 1px solid #E6BA2F;
    margin-left: 20px;
    margin-bottom: 30px;
  }
  .nombre-trabajos{
    color: #5F6368;
    margin-bottom: -10px;
    margin-top: -20px;
    font-size: 23px;
    margin-left: 20px;
  }
  .txt-card-trabajos{
    color: #5F6368;
    margin-bottom: -10px;
    margin-top: 10px;
    font-size: 18px;
    margin-left: 20px;
  }
  .vermas-nosotros {
    font-size:10px;
    width:165px;
    height:38px;
    border-width:1px;
    color:#fff;
    border-color:#E6BA2F;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    background:#E6BA2F;
    margin-top: 100px;
  }
  
  .vermas-nosotros:hover {
    background:#f0bb0f;
  }
  .a-card{
    text-decoration: none;
  }

  /* contacto pag */
 
  .myTab{
    background-color: #6D6D6D;
    margin: 0% auto;
  }
  .todo-elcontacto2{
    display: flex;
    flex-direction: column;
    gap: 50px;
   }
   .contacto-container2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 100px;
    margin-bottom: 100px;
    gap: 30px;
    flex-wrap: wrap;
  }
  .linea-cont{
    height: 256px;
  }
  .contacto-redes-iconos2{
  display: flex;
    flex-direction: column;
    gap: 30px;
    margin-left: 59px;}
  .titulo-vector-contacto{
    color: #f0bb0f;
    font-size: 15px;
    font-weight: 300;
  }
  .parrafo-vector-contacto2{
    color:#F7F7F7;
    font-size: 13px;
    margin-left: 30px;
    width: 300px;
  }
  .titulo-vector-contacto2{
    color: #F7F7F7;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 40px;
  }
  .titulo-pequeño2{
    color: #F7F7F7;
    font-size: 10px;
  }
  .titulo-pequeño22{
    color: #F7F7F7;
    font-size: 14px;
    margin-top: 20px;
    font-weight: 200;
  }
  .titulo-grande2{
    color: #F7F7F7;
    font-size: 20px;
  }
  .titulo-grande{
    font-size: 36px;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 200;
  }

  .copyright2{
    color: #F7F7F7;
  }
  .imagen-ubi{
    width: 80%;
    margin-top: 100px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 100px;
  }
  .home-nav-logo2{
    display: flex;
    flex-direction: row;
    justify-content: normal;
    margin-left: 150px;
    margin-right: 100px;
    padding-top: 48px;
  }
  .cards-yflecha{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }

  .card-nosotros-obras-servicios{
    display: flex;
    flex-direction: row;
margin-left: 100px;
    gap: 50px;

  }
  .linea-carrusel{
    border: none; 
    border-top: 1px solid #E6BA2F; 
    width: 350px; 
    height: 1px;
    margin-top: 10px;
  }
  .card-titulo{
    color: #5F6368;
    font-weight: 500;
  }
  .p-card-carru{
    color: #5F6368;
    font-weight: 300;
    width: 350px;
  }
  .negrita{
    font-weight: 1000;
    color: #373737;
  }
  /* buttonFixedWpp.jsx: */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #075e54cf;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.floating-button img {
  width: 40px;
  height: 40px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  .83% {
    transform: translateX(-5px) rotate(-5deg);
  }
  1.66% {
    transform: translateX(5px) rotate(5deg);
  }
  2.5% {
    transform: translateX(-5px) rotate(-5deg);
  }
  3.33% {
    transform: translateX(0);
  }
  100%{

  }

}

.shake {
  animation: shake 30s ease-in-out infinite;
}

  @media (max-width: 1920px){
 }

@media (max-width: 1280px){
  .circulos-carrusel-home {
    margin-left: -113px;
}
.titulo-pequeño-comen2 {
  width: 310px;
}
.cuadros-proyectos-container {
  display: flex;
  flex-direction: column;}
  .foto-ppal-cuadros-proyectos {
    width: 90%;
    height: 510px;
    background-position-y: -97px;
}
.titulo-cuadros-proyectos {
  margin-top: 427px;
}
.foto-chiquita-cuadros-proyectos {
  width: 90%;
  height: 423px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.foto-chiquita, .foto-chiquita2, .foto-chiquita3, .foto-chiquita4 {
  width: 50%;
  height: 224px;
}
.titulo-cuadros-proyectosB {
  margin-top: 172px;
}
.foto-chiquita-cuadros-proyectos {
  width: 90%;
  height: 474px;
}
.container-txt-proyectos-home {
  width: 38%;}}

@media (max-width: 1170px) {
  .carrusel-gris-img {
    height: 693px;
}
.circulos-carrusel-home {
  margin-left: -70px;
}
.container-txt-proyectos-home{
  width: 37%;
}
}
@media (max-width: 1080px) {
.circulos-carrusel-home {
  margin-left: -40px;
}
.parrafo-pequeño-servicios {
  width: 394px;
}
.imagen-servicios {
  background-position-x: -94px;
}
.imagen-servicios-proyectos{
  background-position-x: -94px;
}
.card-nosotros-obras-servicios {
  margin-left: 73px;}

  .contacto-redes-iconos2 {
    margin-left: 32px;
}
.contacto-container2 {
  margin-left: 48px;
}
.foto-ppal-cuadros-proyectos {
  width: 90%;
  height: 435px;}
  .titulo-cuadros-proyectos {
    margin-top: 369px;
}}

@media (max-width: 1024px) {
/* .react-multi-carousel-list{
  margin-right: 6px;
} */
.parrafo-home-carruselimg-contacto{
  width: 384px;
}
.cuadrito-contacto {
  width: 371px;
}
.card-nosotros-obras-servicios {
  margin-left: 41px;
}
.titulo-pequeño-comen2 {
  width: 252px;
}
.foto-ppal-cuadros-proyectos {
  width: 90%;
  height: 407px;
}
.titulo-cuadros-proyectos {
  margin-top: 339px;
}}






/* @media (max-width: 1024px) {
.cards-carruselam3{
  gap: 61px;
}
.cartelito-contenedor-carruselam {
  padding-right: 10px;
  padding-left: 10px;
}
}

  @media (max-width: 1024px) {
    .home-nav-logo {
      margin-left: 52px;
      margin-right: 57px;
  }
    .container-home-cards {
      display: flex;
      flex-direction: row;
      gap: 100px;
      flex-wrap: wrap;
      justify-content: center;
  }
  .img-nosotros {
    height: 279px;
    width: 432px;
}
.img-nosotros2 {
  height: 291px;
  width: 433px;
  border-radius: 10px;
}
.container-txt-carruselppal {
  gap: 191px;
}

.card-video {
  margin-top: 156px;
  gap: 17px;
  margin-bottom: 165px;
}

  .card-v2 {
    height: 342px;
    width: 269px;
    margin-top: -2px;
}

.parrafo-pequeño-servicios {
  width: 231px;}

.cards-carruselam3 {
  gap: 14px;
}
.linea-coment3-trabajos {
  width: 312px;}
.img-nosotros {
  height: 232px;
  width: 314px;
}.video-red {
  width: 513px;
}} */

  @media (max-width: 900px) {
    .logoppal{
     display: none;
    }
    .navbar-links-container {
      position: absolute;
      top: 0px;
      background-color: var(--color-body);
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: 0.1s ease-in;
      transform: translateY(-130%);
      height: 100%;
      gap: 40px;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(12.5px);
      -webkit-backdrop-filter: blur(12.5px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      direction: rtl;
      overflow: auto;
      margin-left: -104px;
      border-radius: 5px;
      z-index: 3;
  }
    .navbar-links-container> div {
        direction: ltr;}
      .navbar-links-container.open {
        transform: translateY(0%) !important;
      }
     
      .cards-carruselam3{
        gap:18px;
        margin-left: 16px;
      }
      .cartelito-contenedor-carruselam {
        width: 39%;}
        .card-nosotros-obras-servicios {
          margin-left: 103px;
      }
      .flechas-carrusel {
        margin-left: 87px;
    }
      .img-nosotros {
        height: 222px;
        width: 263px;}
        .linea-coment3-trabajos {
          width: 258px;}
          .txt-card-trabajos{
            width: 296px;
          }
          .card-video{
            gap: 34px;
          }
          .video-red {
            width: 476px;
        }
        .card-v2 {
          height: 324px;
          width: 400px;
          margin-top: -33px;
      }

    
      .navbar-links-container.open .link-nav {
        font-size: 20px;
      }.toggle {
        display: flex !important;
        flex-direction: column;
        margin-top: 40px;
        flex-wrap: wrap;
      }
      .toggle-home {
        display: flex !important;
        flex-direction: column;
        margin-top: 40px;
        flex-wrap: wrap;
      }
      .ul-nav {
        flex-direction: column;
        font-size: 20px;
        gap: 30px;
        padding-bottom: -8px;
        margin-top: -69px;
    }
    
      .toggle span {
        width: 25px;
        height: 2px;
        background: #fff;
        margin-bottom: 5px;
        border-radius: 2px;
        transform-origin: 5px 0px;
        transition: all 0.2s linear;
      }
    
      .toggle.open > span {
        transform: rotate(45deg) translate(0px, 0px);
        width: 28px;
      }
      .toggle.open > span:nth-child(2) {
        display: none;
      }
      .toggle.open > span:nth-child(3) {
        transform: rotate(-45deg) translate(-5px, 3px);
        width: 28px;
      }
      .toggle-home span {
        width: 25px;
        height: 2px;
        background: #fff;
        margin-bottom: 5px;
        border-radius: 2px;
        transform-origin: 5px 0px;
        transition: all 0.2s linear;
      }
    
      .toggle-home.open > span {
        transform: rotate(45deg) translate(0px, 0px);
        width: 28px;
      }
      .toggle-home.open > span:nth-child(2) {
        display: none;
      }
      .toggle-home.open > span:nth-child(3) {
        transform: rotate(-45deg) translate(-5px, 3px);
        width: 28px;
      }
      .show {
        display: block !important;
        pointer-events: none;
      }
    
      .logo-toggle{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 40px;
        margin-left: 40px;
        
      }
       
      .logo-toggle2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 40px;
        margin-left: 40px;
        gap: 400px;
        
      }
      .contacto-container2 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 100px;
        margin-bottom: 100px;
        gap: 171px;
        flex-direction: column;
    }
    .linea-cont {
      display: none;
  }
    
      .logo-toggle-home{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 40px;
        margin-left: 40px;
        gap: 600px;
      }
      .logoppal2{
        margin-top: 10px;
        display: inline;
        margin-top: 30px;
       }
       .logoppal2-home{
        margin-top: 10px;
        display: inline;
        margin-top: 30px;
       }
       #paint-splash {
        margin-top: 5px;
    }
    .toggle-home span{
      background:#4EBD9A;
    }
    .li2 {
      color:#FFF;
    }
    .cuadro-blanco-txt {
      margin-left: 211px;
      margin-right: 210px;
    }
    .ul-nav-redes{
    margin-top: 0px;
    margin-bottom: 35px;
    }
   
.img-nosotros2 {
    height: 291px;
    width: 369px;
    border-radius: 10px;
}
.container-txt-carruselppal{
  gap: 147px;
}
.cartelito-contenedor {
  width: 57%;
    }
    .img-nosotros2 {
      height: 257px;
      width: 369px;}
      .foto-chiquita-cuadros-proyectos {
        width: 52%;}
      
        .titulo-pequeño-comen1 {
          width: 231px;
      }
      .parrafo-home-carruselimg-contacto {
        width: 347px;
    }
    .cuadrito-contacto {
      width: 320px;
  }

.logoppal2{
  z-index: 4;
}
.logo-toggle {
  position: sticky;
  z-index: 4;
}
.txt-card-trabajos {
  width: 384px;
}
.linea-coment3-trabajos {
  width: 371px;
}
.contacto-container2 {
  margin-left: -70px;
}
.contacto-redes-iconos2 {
  margin-left: -69px;
}
.logo-toggle2 {
  z-index: 3;
}
.container-txt-proyectos-home {
  width: 49%;
}
.foto-ppal-cuadros-proyectos {
  width: 90%;
  height: 345px;
}
.titulo-cuadros-proyectos {
  margin-top: 276px;
}
.foto-chiquita-cuadros-proyectos {
  width: 90%;
}}

@media (max-width: 853px){
.cuadrito-contacto {
  width: 284px;
}
.react-multi-carousel-list {
  max-width: 100%; /* Asegúrate de que el carrusel no exceda el ancho de la pantalla */
  box-sizing: border-box;
overflow: hidden !important;}
.parrafo-home-carruselimg-contacto {
  width: 326px;
}
.contacto-redes-iconos2 {
  margin-left: -95px;
}
.dosfotos {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

}

    @media (max-width: 820px){
    .cards-carruselam3{
  margin-left: 33px;
    }
    .text-container-cartelito {
      margin-top: -12px;}
 

      .cartelito-contenedor {
        width: 48%;
    }
.ul-nav{
  margin-right: -9px
}

.ul-nav-redes {
  margin-left: 8px;}
  .container-txt-carruselppal{
    flex-direction: row-reverse;
  }
  .container-txt-carruselppal {
    gap: 92px;
    margin-top: 26px;
}
.card-nosotros-obras{
  margin-right: -38px;

}

.boton-cartelito{
  margin-left: -16px;
}
.card-v2 {
  height: 364px;}
  .logo-toggle2 {
    gap: 272px;
}
.cards-carruselam3 {
  margin-left: -2px;
}
.circulos-carrusel-home {
  margin-left: -12px;
}
.container-txt-proyectos-home {
  width: 60%;
}
.parrafo-home-carruselimg-contacto {
  width: 317px;
}
.container-txt-carruselppal {
  margin-top: 127px;
  margin-left: 45px;
}
.imagen-servicios {
  background-position-x: -191px;
}
.imagen-servicios-proyectos{
  background-position-x: -191px;
}
.card-nosotros-obras-servicios {
  margin-left: 39px;
}
.titulo-pequeño-comen2 {
  width: 208px;
}
.logo-toggle2 {
  gap: 333px;
  z-index: 3;
}
.foto-ppal-cuadros-proyectos {
  width: 90%;
  height: 301px;
}
.titulo-cuadros-proyectos {
  margin-top: 247px;
}}
    
  @media (max-width: 800px){
  .cards-carruselam3 {
    margin-left: 23px;
    gap: 22px;
}

.boton-cartelito {
  margin-left: 0px; 
}
.container-txt-carruselppal {
  gap: 114px;
  margin-top: 52px;
  display: flex;
  flex-direction: row-reverse;
}
  .cards-carruselam3 {
    margin-left: 20px;
    gap: 10px;
}
.contenedor-carruselvideo-botones{
  gap: 12px;
}
.img-nosotros2 {
  height: 251px;
  width: 300px;
  border-radius: 10px;
}




.carrusel-am2 {
  width: 100%;}
  .cards-carruselam3 {
    margin-left: 20px;
    gap: 30px;
}
.card-video {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-v2 {
  height: 286px;
  width: 457px;}
  .cards-carruselam3 {
    margin-left: -18px;}
    .img-nosotros2 {
      height: 263px;
      width: 382px;}
      .parrafo-home-carruselimg-contacto {
        width: 252px;
    }
    .container-txt-carruselppal {
      gap: 114px;
      margin-top: 136px;
    }
    .contacto-container2 {
      margin-left: -50px;
  }}
@media (max-width: 768px){
  .cards-carruselam3 {
    margin-left: 20px;
    gap: 107px;
}

.img-nosotros2 {
  height: 236px;
  width: 290px;
  border-radius: 10px;
}
.container-txt-carruselppal {
  gap: 163px;
  margin-top: 52px;
}
.cards-carruselam2 {
  margin-top: 36px;
  margin-left: 16px;
}
.logo-toggle {
  margin-left: 34px;
}
.text-red-ppal {
  gap: 15px;
}
.img-nosotros {
  height: 210px;
  width: 210px;
}


.cartelito-contenedor-carruselam {
  width: 41%;}
  .card-video {
    margin-top: 121px;
    gap: 38px;
    margin-bottom: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-v2 {
  height: 283px;
  width: 468px;
  margin-top: -2px;
}

.circulos-carrusel-home {
  margin-left: 4px;
}
.img-nosotros2 {
height: 244px;
width: 395px;
border-radius: 10px;
}
.cards-carruselam3 {
  margin-left: -26px;
  gap: 3px;
}
.titulo-pequeño-comen1 {
  width: 210px;
}
.container-txt-carruselppal {
  gap: 163px;
  margin-top: 124px;
}
.foto-ppal-cuadros-proyectos {
  width: 90%;
  height: 274px;
}
.titulo-cuadros-proyectos{
  margin-top: 208px;
}
}
@media (max-width: 720px){
.carrusel-gris-img {
  flex-direction: column;
  height: 1000px;
}
.cartelito-contenedor {
  width: 100%;
  margin-top: 100px;
}
.text-container-cartelito {
  margin-top: -12px;
  padding-right: 46px;
}
.img-nosotros2 {
  height: 316px;
  width: 542px;
  border-radius: 10px;
}
.cards-carruselam3 {
  margin-left: 183px;
  gap: 3px;
}
.cards-comentarios {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin-top: 50px;
  gap: 30px;
  margin-left: 52px;
  padding-bottom: 110px;
}
.titulo-pequeño-comen1 {
  width: 411px;
}
.todo-elcontacto {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-left: -201px;
}
.titulo-contacto {
  width: 405px;
}
.titulo-contacto2{
  width: 405px;
}
.parrafo-home-carruselimg-contacto {
  width: 374px;
}
.cuadrito-contacto {
  width: 397px;
}
.titulo-grande-servicios {
  color: #FAFAFA;
  font-size: 34px;
}
.parrafo-pequeño-servicios {
  width: 283px;
  font-size: 21px;
}
.imagen-servicios {
  background-position-x: -253px;
}
.imagen-servicios-proyectos{
  background-position-x: -253px;
}
.titulo-pequeño-comen2 {
  width: 423px;
}
.parrafo-pequeño-servicios {
  width: 221px;
  font-size: 21px;
}
.foto-ppal-cuadros-proyectos {
  width: 90%;
  height: 248px;
}
.ul-nav {
  margin-top: -12px;
}
}
@media (max-width: 712px){

.cuadros-proyectos-container {
  display: flex;
  flex-direction: column;}
.contenedor-carruselvideo-botones{
  gap: 12px;
}
.logo-toggle2 {
  gap: 167px;
}
.cards-carruselam3 {
  margin-left: 180px;
  gap: 10px;
}
.foto-ppal-cuadros-proyectos {
  width: 87%;}
  .foto-chiquita-cuadros-proyectos {
    width: 87%;
}
.cartelito-contenedor-carruselam {
  width: 37%;
}
.contacto-redes-iconos2 {
  margin-left: 2px;
}
.foto-ppal-cuadros-proyectos {
  width: 90%;
  height: 234px;
}
.titulo-cuadros-proyectos {
  margin-top: 185px;
}}

@media (max-width: 640px){
  .text-red-ppal {
    gap: 15px;
    width: 141px;
}
.cards-carruselam3 {
  margin-left: 20px;
  gap: 75px;
}
.todo-elcontacto {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.titulo-contacto {
  width: 100%;
}
.titulo-contacto2{
  width: 100%;
}
.container-hero-home {
  margin-top: 159px;}
.home-nav-logo {
  margin-top: -46px;
}
.home-nav-logo-contacto {
  margin-top: -46px;
}
.home-nav-logo2.logo-toggle {
  margin-top: -46px;
}
.home-nav-logo2 {
  margin-left: 30px;
}
.logo-toggle2 {
  gap: 242px;
}
.ul-nav {

  padding-top: 81px;}

.cards-carruselam3 {
  margin-left: 142px;
  gap: 11px;
}
.container-txt-proyectos-home {
  width: 69%;
}
.todo-elcontacto {margin-left: -11px;}
.card-nosotros-obras-servicios {
  margin-left: 25px;
}
.parrafo-pequeño-servicios {
  width: 196px;
  font-size: 21px;
}
.imagen-servicios {
  background-position-x: -307px;
}
.imagen-servicios-proyectos{
  background-position-x: -307px;
}
.foto-ppal-cuadros-proyectos {
  width: 87%;
  height: 198px;
}
.titulo-cuadros-proyectos {
  margin-top: 148px;
}
}
  @media (max-width: 600px){
    .container-txt-carruselppal {
      gap: 163px;
      margin-top: 52px;
      display: flex;
      flex-direction: column;
  }



.contacto-container2{
  margin-left: 8px;
}

.imagen-servicios {
  height: 798px;
}
.imagen-servicios-proyectos{
  height: 798px;
}
.cards-carruselam2 {
  margin-top: -133px;
  margin-left: -164px;
}
.boton-mediaq{
  margin-top: -19px;
}
.img-nosotros2 {
  height: 236px;
  width: 357px;
  border-radius: 10px;
  margin-left: 38px;
}
.indicadores-circulos {
  margin-top: 30px;
  margin-left: 33px;
}

.carrusel-amarillo {
  display: flex;
  flex-direction: column;
}
.text-container-cartelito-carruselam {
  margin-top: -12px;
  margin-left: -215px;
}
.cartelito-contenedor-carruselam {
  width: 100%;
  padding-left: 0px;
  padding-right: 84px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.carrusel-am2 {
  width: 100%;}
  .cards-carruselam3 {
    margin-left: 18px;
    gap: 62px;
  }
  .video-red {
    width: 468px;
}
.cards-comentarios {
  display: flex;
  flex-direction: column;}
  .coment {
    width: 80%;
}
.img-nosotros2 {
  height: 286px;
  width: 463px;}
  .cards-carruselam3 {
    margin-left: 93px;
    gap: 9px;
}
.indicadores-circulos {
  margin-top: 30px;
  margin-left: 78px;
}
.titulo-cards {
  width: 507px;
  margin-left: 46px;
}
.container-txt-carruselppal {
  gap: 163px;
  margin-top: 112px;
  display: flex;
  flex-direction: column;
  margin-left: 33px;
}
.carrusel-amarillo {
  height: 777px;
}
.cards-yflecha {
  margin-top: 50px;
  margin-left: -65px;
}
.flechas-carrusel {
padding-top: 44px;}
.contacto-container2 {
  margin-left: -30px;
}
.foto-ppal-cuadros-proyectos {
  width: 87%;
  height: 178px;
}
.titulo-cuadros-proyectos {
  margin-top: 137px;
  font-size: 22px;
}}

@media (max-width: 540px){

.text-red-ppal {
  gap: 15px;
  width: 177px;
}
.boton-siguiente {
  background-color: transparent;
  border: none;
  margin-right: -16px;
}
.cards-carruselam3 {
  margin-left: 10px;
  gap: 49px;
}
.video-red {
  width: 421px;
}
.logo-toggle2 {
  gap: 192px;
}
.hero-titulo-home {
  width: 486px;
  color: #f8f8f8;
  font-size: 32px;
}
.titulo-cards {
  width: 507px;
  margin-left: 16px;
}
.cards-carruselam3 {
  margin-left: 86px;
  gap: 5px;
}
.logos-carrusel {
  margin-left: 24px;
}
.todo-elcontacto {
  margin-left: 50px;
}
.flechas-carrusel {
  margin-left: 34px;
}
.imagen-servicios {
  background-position-x: -567px;
}
.imagen-servicios-proyectos{
  background-position-x: -567px;
}
.parrafo-pequeño-servicios {
  width: 294px;
  font-size: 21px;
}
.container-txt-carruselppal {
  gap: 163px;
  margin-top: 169px;}
  .titulo-pequeño-comen2 {
    width: 347px;
}
.cards-comentarios {
  margin-left: 47px;
}
.foto-ppal-cuadros-proyectos {
  width: 87%;
  height: 200px;
  background-position-y: -44px;
}}

@media (max-width: 480px){

.img-nosotros {
  height: 230px;
  width: 351px;
}

.txt-card-trabajos {
  width: 295px;
}
.cartelito-contenedor-carruselam {
  padding-left: 99px;}
  .cards-carruselam2 {
    margin-top: -133px;
    margin-left: -112px;
}
.contacto-container2 {
  margin-left: -5px;
}
.logo-toggle2 {
  gap: 174px;
}
.home-nav-logo2 {
  margin-left: 7px;
}
.titulo-cards {
  width: 450px;
  margin-left: 13px;
}
.hero-titulo-home {
  width: 434px;
  color: #f8f8f8;
  font-size: 29px;
}
.titulo-cards {
  width: 434px;
  margin-left: 22px;
}
.img-nosotros2 {
  height: 259px;
  width: 399px;
}
.cards-carruselam3 {
  margin-left: 60px;}
  .logos-carrusel {
    margin-left: 124px;
}
.titulos-seccion-comentarios {
  margin-left: 32px;}
  .cards-comentarios {margin-left: 32px;}
  .todo-elcontacto {
    margin-left: 32px;
}
.imagen-ubi {
  width: 100%;
  margin-top: 100px;
  margin-left: 0%;
  margin-right: 0%;
  margin-bottom: 100px;
}}

@media (max-width: 430px){

.text-red-ppal {
  margin-left: -112px;
}
.cards-carruselam2 {
  margin-top: -133px;
  margin-left: -88px;
}
.card-v2 {
    height: 283px;
    width: 388px;
    margin-top: -2px;
}
.video-red {
  width: 329px;
}
.home-nav-logo2 {
  margin-left: -21px;
}
.hero-titulo-home {
  width: 398px;
  color: #f8f8f8;
  font-size: 28px;
}
.hero-subt-home {
  font-size: 25px;}
  .titulo-cards {
    width: 347px;
    margin-left: 42px;
}
.carrusel-gris-img {
  flex-direction: column;
  height: 1126px;
}
.logos-carrusel {
  margin-left: 96px;
}
.titulos-seccion-comentarios {
  width: 345px;
}
.titulo-pequeño-comen1 {
  width: 320px;
}
.cuadrito-contacto {
  width: 341px;
}
.input-contacto {
  width: 341px;}
  .input-contacto2 {
    width: 341px;}
    .text-red-ppal {
      margin-left: 8px;
  }
  .titulo-pequeño-comen2 {
    width: 308px;
}
.titulos-seccion-comentarios2 {
  margin-left: 25px;}
  .card-nosotros {
    height: 434px;
    width: 371px;}
    .img-nosotros-obras {
      height: 269px;
      width: 372px;
      border-radius: 10px;
  }
  .linea-coment3-trabajos {
    width: 337px;
}.txt-card-trabajos {
  width: 317px;
}
.card-nosotros {
  height: 460px;
  width: 371px;
}
.foto-ppal-cuadros-proyectos {
  width: 87%;
  height: 200px;
  background-position-y: -1px;
}
.titulo-cuadros-proyectosB {
  font-size: 16px;
  }}

  @media (max-width: 425px){
  .container-txt-carruselppal {
    gap: -15px;
    margin-top: 52px;
    gap: 0px;
    display: flex;
    flex-direction: column;
    margin-left: -46px;
}
.imagen-servicios{
  height: 823px;
}
.imagen-servicios-proyectos{
  height: 823px;
}
.img-nosotros2 {
  height: 243px;
  width: 322px;
  }
  .cards-carruselam2 {
    margin-top: 42px;
    margin-left: 37px;
}
  .img-nosotros {
    height: 209px;
    width: 262px;
}

.cards-carruselam3 {
  margin-left: 18px;
  gap: 38px;
}
.card-v2 {
  height: 283px;
  width: 387px;
  margin-top: -2px;
}
.video-red {
  width: 302px;
}
.contenedor-carruselvideo-botones {
  gap: 13px;
}
.card-video {
  margin-top: 108px;
  gap: 82px;
  margin-bottom: 106px;
}
.cards-comentarios {
  display: flex;
  flex-direction: column;}
  .coment {
    width: 80%;
}
.titulos-seccion-comentarios2{
  width: 80%;
}

.input-contacto, .input-contacto2{
  width: 364px;
}
.titulo-cards {
  margin-left: 26px;
}
.titulo-cards-mas {
  margin-left: -26px;
}
.hero-titulo-home {
  width: 388px;
  font-size: 26px;
}
.imagen-servicios {
  background-position-x: -594px;
}
.imagen-servicios-proyectos{
  background-position-x: -594px;
}
.container-txt-carruselppal {
  margin-top: 158px;
  margin-left: 31px;
}}

@media (max-width: 414px){
  .text-red-ppal {
    margin-left: -79px;
}
.cards-carruselam2 {
  margin-top: 42px;
  margin-left: -13px;
}


.logo-toggle2 {
  gap: 158px;
}
.hero-titulo-home {
  width: 352px;
  font-size: 22px;
}
.hero-subt-home {
  font-size: 22px;
}
.titulo-cards {
  font-size: 23px;
}
.circulos-carrusel-home {
  margin-left: -7px;
}
.img-nosotros2 {
  height: 243px;
  width: 365px;
}
.cards-carruselam3 {
  margin-left: 50px;
  gap: 38px;
}
.titulo-contacto {
  width: 77%;
}
.titulo-contacto2{
  width: 77%;
}
.parrafo-home-carruselimg-contacto {
  width: 349px;
}
.cuadrito-contacto {
  width: 302px;
}
.input-contacto, .input-contacto2 {
  width: 290px;
}
.copyright{
  font-size: 11px;
}
.container-txt-carruselppal {
  margin-top: 176px;
  margin-left: 122px;
}
.contacto-container2 {
  margin-left: -19px;
}
}
@media (max-width: 390px){

.logo-toggle2 {
  gap: 132px;
}
.contacto-container2 {
  margin-left: 1px;
}
.contacto-container2 {
  margin-left: -14px;
}
.titulo-cuadros-proyectosB {
  margin-left: 10px;}
  .cuadros-proyectos-container {
    margin-left: 32px;
}
.boton-home { 
  margin-left: 30px;
}
}

@media (max-width: 384px){
  .text-red-ppal {
    margin-left: -12px;
}
.img-nosotros2 {
  height: 224px;
  width: 290px;
  margin-left: 28px;
}

.text-container-cartelito-carruselam {
  margin-top: -12px;
  margin-left: 17px;
}

.card-v2 {
  height: 314px;
  width: 336px;}
  .input-contacto, .input-contacto2 {
    width: 345px;
}
.titulo-cards {
  margin-left: 7px;
}
.logos-carrusel {
  margin-left: 75px;
}
.input-contacto, .input-contacto2 {
  width: 282px;
}
.titulos-seccion-comentarios {
  width: 278px;
}
.titulo-pequeño-comen1 {
  width: 261px;
}
.parrafo-home-carruselimg-contacto {
  width: 249px;
}
.container-txt-carruselppal {
  margin-top: 176px;
  margin-left: 56px;
}
.cartelito-contenedor-carruselam {
  padding-left: 0px;
}
}
@media (max-width: 360px){


.card-v2 {
  height: 330px;
  width: 334px;
  margin-top: -2px;
}
.video-red {
  width: 283px;
  height: 300px;
}
.input-contacto, .input-contacto2 {
  width: 335px;
}
.contacto-redes-iconos {
  width: 325px;
}
}

@media (max-width: 360px){

.text-red-ppal {
  margin-left: 17px;
}
.img-nosotros2 {
  height: 231px;
  width: 283px;
}
.indicadores-circulos {
  margin-top: 30px;
  margin-left: 21px;
}
.logo-toggle2 {
  gap: 105px;
}
.cards-carruselam3 {
  margin-left: 18px;
  gap: 22px;
}
.video-red {
  width: 260px;
  height: 300px;
}
.hero-titulo-home {
  width: 299px;
  font-size: 21px;
}
.hero-subt-home {
  font-size: 20px;
}
.titulo-cards {
  font-size: 20px;
  width: 235px;
}
.titulo-cards {
  margin-left: 52px;
}
.img-nosotros2 {
  height: 231px;
  width: 322px;
}
.cards-carruselam3 {
  margin-left: 44px;
  gap: 22px;
}
.indicadores-circulos {
  margin-left: 48px;
}
.input-contacto, .input-contacto2 {
  width: 271px;
}
.container-txt-carruselppal {
  margin-top: 176px;
  margin-left: 23px;
}
.img-nosotros-serv {
  height: 232px;
  width: 328px;}
  .linea-carrusel {
    width: 327px;}
    .p-card-carru {
      width: 327px;
  }
  .card-nosotros {
    height: 467px;
    width: 327px;
}
.img-nosotros-obras {
  height: 269px;
  width: 327px;}
  .linea-coment3-trabajos {
    width: 287px;
}
.txt-card-trabajos {
  width: 303px;
}
.contacto-container2 {
  margin-left: -3px;
}
.titulo-cuadros-proyectos {
  font-size: 19px;
  margin-left: 15px;
  margin-top: 160px;
}
}
@media (max-width: 320px){

.text-red-ppal {
  margin-left: 38px;
  gap: 15px;
}
.img-nosotros2 {
  height: 225px;
  width: 271px;
}
.cards-carruselam3 {
  margin-left: 6px;
  gap: 9px;
}
.card-v2 {
  height: 330px;
  width: 296px;
  margin-top: -2px;
}
.logo-toggle2 {
  gap: 72px;
}
.parrafo-vector-contacto2 {
  width: 234px;
}
.video-red {
  width: 240px;
  height: 300px;
}
.img-nosotros-obras {
  height: 265px;
  width: 251px;
  border-radius: 10px;
}

.input-contacto, .input-contacto2 {
  width: 285px;
}
.contacto-redes-iconos {
  width: 291px;
}
.todo-elcontacto{
  padding-left: 12px;
}
.img-nosotros2{
  margin-left: 15px;
} .titulos-seccion-comentarios2 {
  margin-left: 28px;}
  .cards-comentarios{
    margin-left: 16px;
  }
  .card-nosotros {width: 251px}
  .hero-titulo-home {
    width: 246px;
    font-size: 21px;
}
.titulo-grande {
  font-size: 29px;
}
.cards-carruselam3 {
  margin-left: 40px;
  gap: 0px;
  margin-top: 53px;
}
.container-txt-proyectos-home {
  margin-left: 31px;}
  .text-container-cartelito {
    margin-left: 31px;
}
.cuadros-proyectos-container {
  margin-left: 26px;}
  .titulo-cuadros-proyectosB {
    margin-left: 12px;
}
.titulo-cuadros-proyectos {
  margin-left: 20px;
  margin-top: 258px;
}
.boton-home {
  margin-left: 23px;
}
.logos-carrusel {
  margin-left: 52px;
}
.cards-comentarios {
  margin-left: 38px;
}
.input-contacto, .input-contacto2 {
  width: 252px;
}
.todo-elcontacto {
  padding-left: 0px;
}
.todo-elcontacto {
  margin-left: 22px;
}
.todo-elcontacto {
  margin-left: 17px;
}
.container-txt-carruselppal {
  margin-top: 176px;
  margin-left: -8px;
}
.parrafo-pequeño-servicios {
  width: 197px;
}
.text-red-ppal {
  margin-left: 54px;
  gap: 15px;
}
.img-nosotros-serv {
  height: 240px;
  width: 305px;
}
.linea-carrusel {
  width: 305px;
}
.p-card-carru {
  width: 293px;
}
.cards-comentarios {
  margin-left: 25px;
}
.titulo-pequeño-comen2 {
  width: 241px;
}
.txt-card-trabajos {
  width: 232px;
}
.linea-coment3-trabajos {
  width: 216px;
}
.txt-card-trabajos {
  width: 219px;
}
.card-nosotros {
  height: 511px;
}
.contacto-container2 {
  margin-left: 11px;
}
.foto-ppal-cuadros-proyectos {
  background-position-x: -37px;
}
.titulo-cuadros-proyectos {
    margin-top: 150px;
}
.foto-chiquita, .foto-chiquita2, .foto-chiquita3, .foto-chiquita4 {
  background-position-x: -112px;
}
.carrusel-marcas {
  margin-left: 31px;
}
}


@media (max-width: 280px){
.logoppal2{
  width: 110px;
    height: 31px;
}
.hero-titulo-home {
  width: 210px;
  font-size: 19px;
}
.hero-subt-home {
  font-size: 16px;
}
.titulo-cards {
  margin-left: 39px;
  font-size: 17px;
  width: 200px;
}
.titulo-cards-mas {
  margin-left: 7px;
}
.img-nosotros2 {
  height: 175px;
  width: 254px;
}
.img-nosotros2 {
  margin-left: 10px;
}
.logos-carrusel {
  margin-left: 20px;
}
.titulos-seccion-comentarios {
  margin-left: 16px;
}
.titulos-seccion-comentarios {
  width: 207px;
}
.cards-comentarios {
  margin-left: 18px;
}
.titulo-pequeño-comen1 {
  width: 220px;
}
.contacto-container{
  margin-left: 12px;
}
.contacto-redes-iconos {
  width: 211px;
}
.titulo-grande {
  font-size: 27px;
}
.cuadrito-contacto {
  width: 238px;
}
.todo-elcontacto {
  margin-left: 6px;
}
.parrafo-home-carruselimg-contacto {
  font-size: 13px;}
  .parrafo-vector-contacto {
    color: #373737;
    font-size: 12px;
}
.input-contacto, .input-contacto2 {
  width: 234px;
}
.boton-contacto {
  font-size: 10px;
  width: 139px;
  height: 33px;}
  .ul-nav {
    padding-top: 432px;
}
.contacto-container {
  width: 90%;}
  .titulo-grande {
    font-size: 25px;
}
.logo-w{
  width: 150px;
}
.img-nosotros-serv {
  height: 204px;
  width: 272px;
}
.linea-carrusel {
  width: 272px;
}
.p-card-carru {
  width: 244px;
padding-left: 7px;}
.card-titulo {
  color: #5F6368;
  padding-left: 7px;
}
.contacto-container2 {
  margin-left: 29px;
}
.input-contacto, .input-contacto2 {
  width: 209px;
}
.titulo-vector-contacto2{
    width: 200px;
    margin-left: -10px;
}
.parrafo-vector-contacto2 {
  width: 182px;
}
.contacto-container2 {
  margin-bottom: 50px;
  gap: 135px;
}
.copyright2{
font-size: 15px;
}
.titulo-cuadros-proyectosB {
  margin-left: 6px;
}
.ul-nav {
  margin-top: -466px;
}}

@media (max-width: 240px){
.titulo-cards-mas {
  margin-left: 0px;
}
.titulo-cards {
  margin-left: 16px;
}
.text-container-cartelito {
  margin-left: 21px;
  margin-top: -12px;
  padding-right: 23px;
}
.img-nosotros2 {
  height: 160px;
  width: 229px;
}
.indicadores-circulos {
  margin-left: 45px;
}
.carrusel-marcas {
  margin-bottom: 45px;
  margin-left: 21px;
}
.carrusel-marcas {
  margin-bottom: 45px;
  margin-left: 21px;
}
.titulo-pequeño-comen1 {
  width: 192px;
}
.logo-w{
  width: 150px;
}
.parrafo-home-carruselimg-contacto {
  width: 206px;
}
.titulo-grande {
  font-size: 24px;
}
.parrafo-vector-contacto {
  color: #373737;
  font-size: 10px;
}
.input-contacto, .input-contacto2 {
  width: 206px;
}
.cuadrito-contacto {
  width: 210px;
}
.copyright {
  font-size: 9px;
}
.titulo-cardA, .titulo-cardB, .titulo-cardC, .titulo-cardD {
  font-size: 18px;
  margin-bottom: 5px;
}
.text-card-servicios {
  font-size: 13px;
}
.card-servicios {
  width: 210px;}
  .circulo-carrusel {
    width: 53px;
    height: 53px;}
    .circulo-img {
      width: 40px;
      height: 40px;
      margin-left: 7px;
      margin-top: 7px;
  }
  .cards-carruselam3 {
    margin-left: 40px;
    gap: 0px;
    margin-top: 9px;
}
.card-nosotros-obras{
  width: 200px;
}
.img-nosotros2 {
  margin-left: 8px;
}
.indicadores-circulos {
  margin-left: 40px;
}
.container-txt-carruselppal {
  margin-top: 148px;
  margin-left: -29px;
}
.titulo-grande-servicios {
  color: #FAFAFA;
  font-size: 29px;
}
.parrafo-pequeño-servicios {
  width: 158px;
  font-size: 18px;
}
.img-nosotros-serv {
  height: 154px;
  width: 215px;
}
.linea-carrusel {
  width: 215px;
}
.card-nosotros-obras-servicios {
  margin-left: 13px;
}
.p-card-carru {
  width: 200px;
  padding-left: 7px;
}
.titulo-pequeño-comen2 {
  width: 209px;
}
.cartelito-contenedor-carruselam {
  padding-left: 25px;
}
.obras-letrachica {
  font-size: 12px;}
  .titulo-obras {
    font-size: 34px;
}
.subtitulo-obras {
  font-size: 18px;
}
.logo-toggle {
  margin-left: 20px;
}
.boton-cartelito {
  font-size: 8px;
  width: 133px;
  height: 28px;}
  .container-comentarios2 {
    padding-top: 30px;
    margin-top: 1px;
}
.contacto-container {
  margin-top: -25px;}
  .titulos-seccion-comentarios2 {
    margin-left: 23px;
}
.titulo-contacto {
  width: 96%;
}
.titulo-contacto2{
  width: 96%;
}
.txt-card-trabajos {
  width: 194px;
}
.card-nosotros {
  width: 225px;
}
.img-nosotros-obras {
  height: 236px;
  width: 225px;
  border-radius: 10px;
}
.card-nosotros {
  height: 497px;
}
.linea-coment3-trabajos {
  width: 194px;
}
.contacto-container {
  margin-top: 75px;
}
.contacto-container2 {
  margin-left: 20px;
}
.titulo-contacto2{
  width: 72%;
}
.input-contacto, .input-contacto2 {
  width: 197px;
}
.contacto-redes-iconos2 {
  margin-left: -16px;
}
.cuadrito-contacto {
  width: 137px;
}
.contacto-container2 {
  width: 85%;}
  .copyright2 {
    font-size: 9px;
}
.foto-ppal-cuadros-proyectos {
  background-position-x: -69px;
}
.foto-chiquita, .foto-chiquita2, .foto-chiquita3, .foto-chiquita4 {
  background-position-x: -144px;
}}

